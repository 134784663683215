// expansion panel object
//
// Use this class for to modify certain styles inherited from mat-expansion-panel
// Typically you'll want to use either a `<div>` or a `<span>` element.
//
// Markup:
// <div class="gap-inp-view">
//    <span class="gap-inp-view__lbl"></span>
//    <span class="gap-inp-view__value"></span>
// </div>
//
// Styleguide objects.exp-panel

.gap-exp-panel {
  background: $white;
  border: 1px solid $border-color;
  margin-top: -1px !important;

  &__header {
    // border-bottom: 1px solid $border-color;
    height: 60px;
    padding: 0 !important;
  }
  &__secondary-txt{
    opacity: 0.8;
    font-size: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-content {
    display: inline;
  }
  .mat-expansion-panel-body{
    padding: 0 !important;
  }
}

// to make the card expanded work
.gap-exp-panel-wrapper{
    margin: 0 15px;
    &__paginator {
      margin-bottom: 5rem;
    }
}

// Mat Expansion Panel - spacings after expansion
mat-expansion-panel.mat-expanded {
  margin: 50px -12px 50px -12px !important;
  border: none;
  box-shadow: 0 4px 8px 0 rgb(96 101 123 / 20%);
}
.gap-exp-panel-wrapper mat-expansion-panel:first-child {
  &.mat-expanded {
    margin-top: 0px !important;
  }
}
.gap-exp-panel-wrapper mat-expansion-panel:last-child {
  &.mat-expanded {
    margin-bottom: 0px !important;
  }
}

// Sub section
.gap-sub-section{
  border-top: 1px solid $border-color;
}

.gap-expanded-body {
  padding: 0 15px 16px !important;
}
