// input object
//
// Use this class when mat inputs are displayed
// Typically you'll want to use either a `<div>`element. mat-form-field is wrapped with this class
//
// Markup:
// <div class="gap-inp-field">
//    <mat-form-field>
//    </mat-form-field>
// </div>
//
// Styleguide objects.inp-field

.gap-inp-field{
    mat-form-field{
        width: 100%;
    }
    &__lbl {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.8;
        margin-bottom: 0.3rem;
    }
    &__icon{
        position: absolute;
        right: 8px;
        cursor: pointer;
        top: 10px;
    }

    // medium mat input
    &--md{
        font-size: 16px;
        .mat-form-field-wrapper{
            margin: 0;
            background: #FFF;
        }
    }
    // small mat input
    &--small{
        font-size: 15px;
    }
    // small mat input
    &--search{
        font-size: 12px;
        .mat-form-field-wrapper{
            margin: 0;
            padding-bottom: 0;
        }
    }
    &--rounded{
        border-radius: 12px;
    }
    &--white-bg{
        .mat-form-field-wrapper{
            background: $white;
        }
    }

    &--height-reduced {
        .mat-form-field-infix {
            padding-top: 0.75em;
            padding-right: 2.3rem !important;
        }
    }

    // input types
    &--autocomplete {
        mat-option{
            height: fit-content !important;
        }
        .mat-checkbox-label {
            white-space: break-spaces !important;
        }
    }

    .mat-focused .mat-form-field-required-marker{
        color: #f44336 !important;
    }

    &__edit-mode {
        .mat-focused {
            .gap-inp-field__star {
                color: #f44336 !important;
            }
        }
    }
}

input:-webkit-autofill {
    -webkit-background-clip: text;
}

// input file
.gap-file-inp{
    &__inner-block{
        position: relative;
        width: 380px;
        height: 180px;
        background: #eef2f8;
    }
    &__abs{
        position: absolute;
        top: 31%;
        left: 27%;
    }
    &__file-name{
        position: absolute;
        left: 0;
        width: 100%;
        top: 61%;
        text-align: center;
        font-size: 14px;
        z-index: 9;
        font-weight: 500;
    }
    &__overlay {
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }
}

@media only screen and (max-width: 1387px) {
    .gap-inp-field--search {
      width: 40%;
      .gap-inp-field--search{
          width: unset;
      }
    }
    .gap-search-box-wrapper{
        .gap-inp-field--search{
            width: unset;
        }
    }
}

.gap-search-box-wrapper{
    width: 50%;
    &--reset-width {
        width: unset;
    }
}
