// loader object
//
// global loader for the whole app
//
// Markup:
// <div class="gap-loader">...</div>
//
// Styleguide objects.loader

.gap-loader{
   background-color: #0000001f;
   position: fixed;
   width: 100%;
   top: 0px;
   left: 0px;
   height: 100vh;
   align-items: center;
   justify-content: center;
   display: flex;
   z-index: 1001;
   &__inner-wrapper {
      width: 50px;
      height: 50px;
      margin-left: 10rem;
      display: inline-block;
      overflow: hidden;
      background: none;
   }
   &__circle {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(0.5);
      backface-visibility: hidden;
      transform-origin: 0 0;
      div {
         position: absolute;
         width: 59px;
         height: 59px;
         border: 11px solid #791fe2;
         border-top-color: transparent;
         border-radius: 50%;
         box-sizing: content-box;

         // spinner rotating animation
         animation: loader-rotating-trans 1s linear infinite;
         top: 50px;
         left: 50px
      }
   }
}


// loader rotating transition
@keyframes loader-rotating-trans {
   0% { transform: translate(-50%,-50%) rotate(0deg); }
   100% { transform: translate(-50%,-50%) rotate(360deg); }
}
