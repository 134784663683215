.gap-chip {
    padding: 4px 12px;
    font-size: 14px;
    width: fit-content;
    border-radius: 24px;
    &--primary {
        background: rgba(39, 174, 96, 0.16);
        color: $success;
    }
    &--secondary {
        background: rgba(244, 121, 32, 0.16);
        color: $accent;
    }
}