// .gap-login-container{
//     height: 100vh;
//     &__img-section img{
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }
//     &__form-section div{
//         width: 100%;
//     }
// }

// color theme

$primary-color: #091e40;
$accent-color: #673ab7;
$accent-color-shade: #7d5fff;


* {
    box-sizing: border-box;
  }
  
  .main-container {
    min-height: 100vh;
    background-color: #ecf4f3;
    
    display: flex;
    align-items: center;
    justify-content: center;
  
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
  }
  
  .main-container a {
    text-decoration: none;
  }
  
  .main-container .login-wrapper {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 172px 6px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 172px 6px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 172px 6px rgba(0,0,0,0.2); 
  
    width: 100%;
    max-width: 900px;
    min-height: 520px;
    max-height: 743px;
    display: flex;
  }
  
  /* Estilos del contenedor del formulario */
  .main-container .login-wrapper .form-wrapper {
    min-width: 45%;
    
    display: flex;
    flex-direction: column;
  }
  
  /* Estilos del header del formulario */
  .main-container .login-wrapper .form-wrapper .form-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    padding: 15px;
    margin-top: 15px;
  
    font-weight: 900;
  }
  
  .main-container .login-wrapper .form-wrapper .form-header .fa-arrow-left,
  .main-container .login-wrapper .form-wrapper .form-header .register-link,
  .main-container .login-wrapper .form-wrapper .form-container .forgot-pass {
    color: #686b72;
  }
  
  /* Estilos del formulario y sus mensajes */
  .main-container .login-wrapper .form-wrapper .form-container {
    padding: 0px 60px;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .form-messages {
    text-align: left;
    line-height: 20px;
    margin-bottom: 30px;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .form-messages h2 {
    margin-bottom: 15px;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .form input {
    width: 100%;
    margin-bottom: 15px;
    background: #e1f2ff !important;
    border: none;
    padding: 10px;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .form input:focus {
    outline: 2px solid $accent-color;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .form a {
    display: block;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .form .forgot-pass {
    text-align: right;
    font-weight: 900;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .form .login-button {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 10px;
    border-radius: 50px;
    background-color: $accent-color;
    color: #fff;
    text-align: center;
    width: 100%;
  }
  
  /* Separador */
  .main-container .login-wrapper .form-wrapper .form-container .separator {
    border: 2px solid #e8e9ea;
    margin-bottom: 35px;
  }
  
  /* Otras opciones de login */
  .main-container .login-wrapper .form-wrapper .form-container .login-options {
    margin: 20px 20px;
    text-align: center;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .login-options .login-text {
    margin-bottom: 15px;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .login-icons {
    font-size: 20px;
  }
  
  .main-container .login-wrapper .form-wrapper .form-container .login-icons .fa-facebook-square,
  .main-container .login-wrapper .form-wrapper .form-container .login-icons .fa-google,
  .main-container .login-wrapper .form-wrapper .form-container .login-icons .fa-twitter {
    color: $accent-color;
    padding: 0px 10px;
  }
  
  /* Estilos del lado derecho */
  
  .main-container .image-wrapper {
    // background: url("https://unsplash.com/photos/mq6GbT4E8e8/download?force=true");
    background-position: center;
    background-size: cover;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 75%;
    img {
      width: 100%;
      height: 95vh;
    }
  }
  
  .main-container .image-wrapper .image-message {
    color: #fff;
    line-height: 20px;
  
    padding: 50px 70px 70px 40px;
  
    display: flex;
    flex-direction: row;
  }
  
  .main-container .image-wrapper .image-message .image-hr-container {
    margin: 5px 20px;
  }
  
  .main-container .image-wrapper .image-message .image-hr-container .image-hr {
    border: 1px solid #e8e9ea;
    min-width: 30px;
  }
  
  .main-container .image-wrapper .image-message .image-text h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .app-logo{
    width: 60px;
  }

  .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]){
    background-color: #686b72 !important;
  }

  .mat-error{
    font-size: 13px;
  }

  .gap-inp-initial {
    &--customized{
      width: 78%;
      .mat-form-field-label-wrapper{
          top: 0em;
          left: 10px;
      }
      &.mat-form-field-should-float .mat-form-field-label-wrapper{
          top: -1.2em !important;
          label{
              transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.00106px) !important;
          }
      }
      input{
          font-size: 0.8rem;
          font-weight: 500;
      }
      mat-icon {
        font-size: 20px;
        margin-top: 3px;
      }
    }
    &--password{
      input {
        padding-right: 40px !important;
      }
    }
  }