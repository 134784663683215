.gap-scroll-bar::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: $sb-bg-color;
}

.gap-scroll-bar::-webkit-scrollbar
{
    width: 5px;
    height: 4px;
	background-color: $sb-bg-color;
}

.gap-scroll-bar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: $sb-track-color;
}

html.cdk-global-scrollblock {
	overflow-y: hidden;
}
html.cdk-global-scrollblock{
	top: 0 !important;
}