// button object
//
// Use this class when you want a button
// Typically you'll want to use a `<button>`element.
//
// Markup:
// <button class="gap-btn gap-btn--primary"></button>
//
// Styleguide objects.button

.gap-results-header{
    margin: 0 15px;
    &__title{
        font-size: 0.7rem;
        opacity: 0.66;
        margin-bottom: 0;
        word-break: break-word;
        white-space: break-spaces;
        &--pl {
            padding-left: 3.7rem;
        }
    }
    &__cb {
        width: 40px;
        margin-right: 12px;
        display: inline-flex;
        justify-content: center;
        label.mat-checkbox-layout {
            margin-bottom: unset;
        }
    }
    &__filter-chip {
        z-index: 99;
    }
}