// modal object
//
// Use this class when you want to customize mat-dialog
//
// Styleguide objects.modal

.gap-modal{
    &--stepper{
        .mat-dialog-container{
            padding: 12px 0;
        }
    }
    &--focused-view{
        width: 100vw;
        max-width: 100vw !important;
        height: calc(100% - 112px);
    }
}