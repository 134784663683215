$primary:#0d8a72;
$primaryBlue: #673ab7;
$primary-dark: #003399;
$primary-variant: rgba(74, 0, 224, 0.2);
$accent: #f47920;
$error: red;
$success: #27ae60;
$bg-blue:#007eff;
$bg-red: #ed1f24;

$app-bg-color: #f5f6ff;

$primary-txt: rgba(0,0,0, 0.8);

$border-color: #d4d4d4;
$white: #FFF;

// Scroll-bar colors
$sb-bg-color: #F8F8F8;
$sb-track-color: rgb(141, 141, 141);