// focused view object
//
// this class is used to enhance UI of focused view(full screen modal)
//
// Styleguide objects.focused-view

.gap-focused-view{
    form {
        animation: slide-down 1s forwards;
    }
    &__title{
        margin: 0 0 0 1.3rem;
    }
    &__stepper{
        // To align the mat-stepper header with page header
        .mat-vertical-stepper-header .mat-step-icon {
            margin-right: 1.8rem;
        }
        .mat-vertical-content{
            padding-left: 2.5rem;
        }
    }
    &__btn-section{
        height: 80px;
        padding-left: 4.3rem;
        background: $app-bg-color;
        border-top: 1px solid $border-color;
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 100;
    }
}