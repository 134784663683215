h6 {
    font-size: 0.92rem;
    opacity: 0.9;
}

.gap-ordinal {
    font-size: 0.55rem;
    position: absolute;
    margin-top: -5px;
    margin-left: 2px;
}

// capitalize only first character of each word
// for all capital words we need to make all letters to simple first
.gap-capitalize {
    display:inline-block;
    text-transform: lowercase;
}
.gap-capitalize:first-letter {
    text-transform: uppercase;
}