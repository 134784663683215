.pg-con {
  &__add {
    &__form {
      padding: 0px 1.3rem;
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__detail-view {
    &__container {
      transition: all 0.5s ease;
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0;
    }
  }
}
