// Result Summary Component
//
// Use this class for the results strip.
// Typically you'll want to use either a `<section>` or a `<div>` element.
//
// Markup:
// <section class="gmp-result-summary"></section>
//
// 3 columns are available to show the data(1 image or icon and 2 texts)
//
// Styleguide components.result-summary

.gmp-result-summary{
    padding: 0 15px;
    height: 60px;
    &__cb {
        min-width: 40px;
        max-width: 40px;    
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        label.mat-checkbox-layout {
            margin-bottom: unset;
        }
    }
    &__icon{
        background: #eee;
        color: rgba(0, 0, 0, 0.38);
        min-width: 40px;
        max-width: 40px;    
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
    }
    &__primary-txt{
        font-size: 0.8rem;
        font-weight: 500;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        &--ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: unset;
        }
    }
    &__secondary-txt{
        font-size: 0.8rem;
        font-weight: 400;
    }
    &__status {
        font-size: 0.7rem;
        font-weight: 400;
        &--success {
            color: $success;
        }
    }
    &__icon-btn-grp{
       button{
        margin: 0px 3px 0px 3px;
            mat-icon{
                display: inline-block;
                font-size: 24px;
                cursor: pointer;
                color: #7a777a !important;
            }
        &:hover{
            background: #b7b7b726;    
        }
        &:focus{
            outline: none;
        }
       
       }
    }
    &--selected {
        background: #d3c5f9;
    }
}