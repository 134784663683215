.gap-tag {
    font-size: 13px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 5px;
    color: #FFF;
    margin-bottom: 0 !important;
    &__lbl {
        font-size: 11px;
        font-weight: 400;
        opacity: 0.7;
    }
    &--filter {
        background: #f47920;
    }
    &--search {
        background: #0d8a72;
    }
}