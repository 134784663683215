.gap-status {
    &--error {
        color: $error;
    }
    &--warning {
        color: $accent;
    }
    &--success {
        color: $success;
    }
    &--info {
      color:$bg-blue;
  }
}
