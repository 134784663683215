/* Global scss importer */

// Settings
@import "./settings/settings.colors";

// generics
@import './generics/generics.animation';
@import './generics/generics.layout';
@import './generics/generics.scroll-bar';
@import './generics/generics.components';
@import './generics/generics.typography';
@import './generics/generics.status';

// objects
@import './objects/objects.exp-panel';
@import './objects/objects.sec-header';
@import './objects/objects.inp-view';
@import './objects/objects.inp-field';
@import './objects/_object.paginator';
@import './objects/objects.button';
@import './objects/objects.results-header';
@import './objects/objects.tab';
@import './objects/object.table';
@import './objects/objects.modal';
@import './objects/objects.focused-view';
@import './objects/object.notification';
@import './objects/objects.info-icon';
@import './objects/objects.loader';
@import './objects/objects.link';
@import './objects/objects.inp-autocomplete';
@import './objects/objects.radio-btn';
@import './objects/objects.chip';
@import './objects/objects.image-preview';
@import './objects/objects.footer';
@import './objects/objects.filter-chip';
@import './objects/objects.tag';

// components
@import './components/components.side-nav';
@import './components/components.header';
@import './components/components.breadcrumb';
@import './components/components.result-summary';
@import './components/components.login';
@import './components/components.expanded-header';
@import './components/components.report';
@import './components/components.pg-connection';
@import './components/components.forgot-password';
@import './components/components.empty-cmp';
@import './components/components.page-not-found';
@import './components/components.dashboard';
@import './components/components.info-box';
@import './components/components.confirm-remove';
@import './components/components.gap-loader-modal';
@import './components/components.footer';
@import './components/components.report-not-modal';

// trump
