.gap-empty{
    display: flex;
    justify-content: center;
    &__img{
        width: 160px;
    }
    &__primary-txt{
        color: black;
    }
    &__secondary-txt{
        color: black;
        opacity: 0.66;
    }
}