.gap-filter-chip {
    background: var(--white);
    font-size: 0.7rem;
    opacity: 0.66;
    font-weight: 500;
    border-radius: 24px !important;
    border: 1px solid #d4d4d4 !important;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > span {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__lbl {
        text-align: left;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--active {
        background: #d3c5f9;
        opacity: 1;
        font-weight: 500;
        color: #000;
    }
}

.gap-filter-chip-menu {
    mat-radio-button.mat-radio-button {
        width: 100%;
    }
}