.gap-notification{
    // removing mat-component styles
    color: unset !important;
    background: unset !important;
    box-shadow: unset !important;
    margin: unset !important;
    padding: unset !important;
    max-width: 50vw !important;
    margin-bottom: 40px !important;
    &__inner-wrapper{
        border-radius: 4px;
        box-sizing: border-box;
        display: block;
        max-width: 33vw;
        min-width: 344px;
        padding: 14px 16px;
        min-height: 48px;
        transform-origin: center;
        z-index: 999;
        color: rgba(255,255,255,.7);
        background: #323232;
        box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
        // margin-left: 35%;
    }
    &__icon{
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: rgba(255,255,255,0.8);
        .material-icons{
            font-size: 18px;
        }
        &-inner{
            width: 28px;
            height: 28px;
        }
    }
    &__title{
        font-size: 14px;
        color: $white;
        font-weight: 400;
    }
    &--full-screen{
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(46, 204, 113, 0.7);
        padding-top: 14rem;
        text-align: center;
    }
}