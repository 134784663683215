// link object
//
// Use this class when mat links are displayed
// Typically you'll want to use either a `<a>`element.
//
// Markup:
// <a class="gap-link">Test Link
// </a>
//
// Styleguide objects.link

.gap-link{
    font-size: 0.9rem;
    cursor: pointer;
    &--small{
        font-size: 0.8rem;
        font-weight: 500;
        opacity: 0.88;
    }
    &--basic {
        color: #000;
        opacity: 0.7;
        text-decoration: underline;
    }
    &--style{
        text-decoration: underline;
    }
}