.gap-inp-autocomplete {
    &__option {
      height: fit-content !important;
    }
    &__label {
      white-space: break-spaces;
    }
  
    .mat-checkbox-label {
      padding-left: 12px;
    }
  }
  