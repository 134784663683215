.gap-report-not-modal {
    text-align: center;
    padding: 0 3rem;
    &__message {
        text-align: center;
        margin: 1rem 0;
        font-size: 0.9rem;
        font-weight: 400;
    }
    &__secondary-message {
        font-size: 0.8rem;
    }
    &__highlighted {
        color: $error;
        font-weight: 500;
    }
}