:root {
    --initialPosition: 2rem;
    --afterAnimationPosition: 24;
    --fadeInUpDuration: 0.5s;
  }
  
  // Start - page content load animation (class)
  @-webkit-keyframes fadeInUp {
    0% {
      transform: translateY(var(--initialPosition));
      opacity: 0;
    }
    100% {
      transform: translateY(var(--afterAnimationPosition));
      opacity: 1;
    }
  }
  @-moz-keyframes fadeInUp {
    0% {
      transform: translateY(var(--initialPosition));
      opacity: 0;
    }
    100% {
      transform: translateY(var(--afterAnimationPosition));
      opacity: 1;
    }
  }
  @-o-keyframes fadeInUp {
    0% {
      transform: translateY(var(--initialPosition));
      opacity: 0;
    }
    100% {
      transform: translateY(var(--afterAnimationPosition));
      opacity: 1;
    }
  }
  @keyframes fadeInUp {
    0% {
      transform: translateY(var(--initialPosition));
      opacity: 0;
    }
    100% {
      transform: translateY(var(--afterAnimationPosition));
      opacity: 1;
    }
  }
  
  @mixin gap-fade-in-up-transition($transTime: var(--fadeInUpDuration), $delay: 0) {
    animation: fadeInUp $transTime ease forwards;
    animation-delay: $delay;
  }
  // End - page content load animation

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}