.gap-pnf-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    min-width: 100vw;
    &__img{
        width: 25vw;
        max-width: 220px;
    }
    &__header-img{
        width: 60px;
    }
    &__title {
        font-size: 1.2rem;
    }
    &__description {
        opacity: 0.6;
        font-size: 0.83rem;
    }
    &__image > div {
        height: 35vh;
    }
}