// Application header
//
// Use this class for the primary call to action button.
// Typically you'll want to use either a `<header>` element.
//
// Markup:
// <header class="gap-header"></header>
//
// Styleguide components.header

.gap-header{
    background: #8E2DE2;  /* fallback for old browsers */
    background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    z-index: 99;

    // header title
    &__title{
        margin-bottom: 0; // to remove the bootstrap margin bottom
        color: $white;
    }

    &__space {
        flex-wrap: wrap;
        display: flex;
    }
    &--fixed {
        position: fixed;
    }
    &__divider {
        width: 1px;
        height: 56px;
        margin: 0;
        background-color: #09756B;
    }
    &__profile-image{
        span{
            font-size: 32px;
            color: #FFF;
            opacity: 0.56;
        }
    }
    
    &--text-alternate {
        color: #fbfdff;
    }
    
    &--side-component {
        flex-grow: 1;
        justify-content: space-between;
    }

    &--side-component1{
        align-items: center;
        align-content: center;
        justify-content: flex-start;
    }
    
    &--side-component3{
        height: 100%;
        width: 1px;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
    }
    
    &__user-image{
        width: 46px;
        height: 46px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    &__profile-data{
        padding: 0 0 0 18px;
        flex-direction: column;
        height: 56px;
        display: flex;
        justify-content: center;
        color: $white;
    }
    &---side-component6 {
        height: auto;
        min-height: 2rem;
        width: 40px;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 0;
        overflow: visible;
        background-color: unset;
    }
    .gap-subtitle-2 {
        font-family: Open Sans,sans-serif;
        font-size: 0.8125rem;
        opacity: 0.7;
    }
    &__hamburger-menu {
        cursor: pointer;
        margin: 0 21px;
        font-size: 1.5rem;
        color: #fff;
    }
}

@media (max-width: 768px){
    .gap-header{
        &__profile-data{
            display: none;
        }
    }
}
