
.gap-table {    
    overflow-x: auto;
    box-shadow: none !important;
    // for merchant signatory table
    &--remove-x-spacings {
        table {
            width: 100% !important;
            margin: unset !important;
            td {
                padding-left: 0 !important;
                padding-right: 30px !important;
            }
        }
    }

    table {
        width: 99%;
        margin: 5px;
        margin-top: 20px;

        th.mat-sort-header-sorted {
            color: black;
        }
        th, td {
            overflow: hidden;
            width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 15px;
            padding-right: 15px;
        }
    
        th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
            padding-left: 15px;
            padding-right: 15px;
        }
        th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    
   
    &__mat-action{
        .material-icons{
            font-size: 18px;
            margin-left: -22px;
        }
    }
}
 

