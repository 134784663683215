// overwrite boostrap button focus outline
button:focus {
    outline: none !important;
}

// input type=file
input[type="file"] {
    display: none;
}
.gap-file-upload {
    background: $primary-dark;
    color: $white;
    padding: 12px 24px;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 5px;
    z-index: 1;
}

// to overwrite ellipsis texts in mat-options
.mat-select-panel {
    .mat-option {
        word-break: break-word !important;
        white-space: break-spaces !important;
        min-height: 3em;
        height: unset !important;
        padding: 12px !important;
    }
    .mat-option-text {
        line-height: 1.4 !important;
    }
}
.mat-autocomplete-panel {
    .mat-option {
        word-break: break-word !important;
        white-space: break-spaces !important;
        min-height: 3em;
        height: unset !important;
        padding: 12px !important;
    }
    .mat-option-text {
        line-height: 1.4 !important;
    }
}

// remover truncating of text in mat tooltip
.mat-tooltip {
    word-break: break-all !important;
    white-space: normal !important;
}