.gap-report{
    margin-top: 50px;

    &__label{
        font-size: 14px;
        font-weight: 500;
        width: 100%;
    }

    &__button-group{
        button{
            margin-right: 5px;
        }
    }
    &__button-group-lbl{
        font-size: 14px;
        opacity: 0.7;
    }
}