.gap-breadcrumb {
    background: none;
    font-size: .8em;
    margin: 0;
    padding: .3rem 1rem 0.8rem;
    &__page-indicator-but {
        width: 20px;
        height: 20px;
        position: relative;
        float: left;
        margin-left: -18px;
        margin-top: 4px;
    }
    &--img-play-back-opt {
        background: transparent url(../../images/outlets/bredcrumb.png) 0px 0px no-repeat;
    }
    a, span {
        margin-right: 6px;
        color: #444444;
        text-decoration: none;
    }
    a:hover, span:hover {
        color: #444444;
        text-decoration: none;
    }
    &__list{
        list-style: none;
        float: left;
        margin: 5px;
        font-size: 14px;
        display: inline-flex;
        list-style-type: none;
        cursor: pointer;
    }
    &__list:last-child {
        margin-right: 20px;
    }
    &__list::after {
        content: "/";
        color: #b1b1b1;
        margin-left: 6px;
    }
    &__list:last-child::after {
        content: ''
    }
    &__title{
        font-size: 1.25rem;
        height: 35px;
        line-height: 80px;
        margin-left: -112px;
    }
}
