.gap-side-nav{
    background: #242237;
    height: auto;
    min-height: 100vh;
    box-shadow: 0 0.5rem 1rem 0 #ccc;
    color: #414d6a;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.25rem;

    &__sidebar{
        padding: 16px 0px 0px 16px;
    }
    &__menu {
        width: 100%;
        height: auto;
    }
    &__menu-items{
        margin: 0;
        padding: 0;
        list-style-type: none;
        overflow: hidden;
    }
    
    &__menu-item{
        border-top: none;
        
        color: #414d6a;
        font-size: 0.9375rem !important;
        font-weight: 400 !important;
        line-height: 1.25rem !important;
        &--active {
            color: #3bb894 !important;
        }
    }
    &__menu-title{
        flex: 1 0 auto;
        width: 140px;
    }
    
    .menu-item-a{
        display: flex;
        text-decoration: none;
        align-items: center;
        font-size: 0.8125rem;
        font-weight: 600;
        line-height: 1.5rem;
        padding: 0.75rem 1rem;
        color: #707788;
        border-bottom: 1px solid #4f4f4f;

        &:hover {
            background-color: transparent;
            color: #3bb894;
            cursor: pointer;
        }
        
           
        
    }
    &__menu-icon{
        font-size: 1.25rem;
        margin-right: 12px;
        width: 1em;
        text-align: center;
        height: 1em;
        vertical-align: top;
    }

    &--sub-menu-items{
        .menu-item-a{
            margin-left: 30px;
        }
    }
    .has-arrow {
        position: relative;
        &::after {
            position: absolute;
            content: '';
            width: 7px;
            height: 7px;
            border-width: 1px 0 0 1px;
            border-style: solid;
            border-color: #ccc;
            right: 1em;
            -webkit-transform: rotate(135deg) translate(0, -50%);
            -ms-transform: rotate(135deg) translate(0, -50%);
            -o-transform: rotate(135deg) translate(0, -50%);
            transform: rotate(135deg) translate(0, -50%);
            -webkit-transform-origin: top;
            -ms-transform-origin: top;
            -o-transform-origin: top;
            transform-origin: top;
            top: 25px;
            -webkit-transition: all .3s ease-out;
            -o-transition: all .3s ease-out;
            transition: all .3s ease-out;
        }
       
    }
    .active > .has-arrow::after,
    li > .has-arrow.active::after,
    .has-arrow[aria-expanded="true"]::after {
        -webkit-transform: rotate(-135deg) translate(0, -50%);
        -ms-transform: rotate(-135deg) translate(0, -50%);
        -o-transform: rotate(-135deg) translate(0, -50%);
        top: 45%;
        width: 7px;
        transform: rotate(-135deg) translate(0, -50%);
    }
    .collapse.in {
        display: block;
    }
}
