// expansion panel expanded header
//
// Use this class for the exapnded panel header.
//
// Styleguide components.expanded-header

.gap-expanded-header{
    border-bottom: 1px solid $border-color;
    height: 60px;
    padding: 0 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &__wrapper {
        flex: 1;
        min-width: 0;
    }
    &__title {
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__SubTitle {
      margin-bottom: 0;
      font-size: small;
      margin-left: 10px;
      margin-right: 10px;
      font-weight: normal;
  }
    &__icon {
        opacity: 0.88;
    }
}
