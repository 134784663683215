// button object
//
// Use this class when you want a button
// Typically you'll want to use a `<button>`element.
//
// Markup:
// <button class="gap-btn gap-btn--primary"></button>
//
// Styleguide objects.button

.gap-btn{

    // background filled
    &--primary{
        background: $primaryBlue;
        color: $white;
        height: 48px;
        width: 100%;
        letter-spacing: 0.15px;
    }
    &--small{
        background: $primary-variant;
        color: $primary-txt;
        height: 40px;
        border-radius: 26px !important;
        // button Text Overflow Fixed
        width: fit-content;
        overflow-x: hidden !important;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &--lg{
        height: 72px;
        border-radius: 26px !important;
    }
    &--md{
        height: 54px;
        border-radius: 12px;
    }

    &--success{
        background: $success;
    }
    &--danger{
        background: rgb(219, 75, 75);
        color: $white;
        border: none;
        font-size: 14px;
        padding: 12px 24px;
        letter-spacing: 0.9px;
    }
    &--white-transparent-bg{
        background: rgba(255,255,255,0.4);
    }
    &--width-small {
        width: fit-content;
    }
}

// reset button component
.gap-reset-btn {
    width: 100%;
    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}