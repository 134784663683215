// input view object
//
// Use this class for to show a label and value attached to it
// Typically you'll want to use either a `<div>` or a `<span>` element.
//
// Markup:
// <div class="gap-inp-view">
//    <span class="gap-inp-view__lbl"></span>
//    <span class="gap-inp-view__value"></span>
// </div>
//
// Styleguide objects.inp-view

.gap-inp-view{
    margin: 15px 0;

    // input label
    &__label{
        font-size: 14px;
        opacity: 0.7;
        margin-bottom: 4px;
    }
    // input value
    &__text{
        font-size: 16px;
        font-weight: 500;
        opacity: 0.88;
        overflow-wrap: anywhere;
    }
    &__img {
        img {
            width: 180px;
            height: 120px;
        }
    }
}
