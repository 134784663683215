.gap-loader-modal-wrapper {
    mat-dialog-container {
        padding: 1.5rem;
    }
}

.gap-loader-modal {
    &__loader {
        position: unset;
        background: unset;
        height: unset;
        .gap-loader__inner-wrapper {
            margin-left: unset;
        }
    }
    &__message {
        text-align: center;
        margin: 1rem 0 0rem;
        font-size: 1rem;
        font-weight: 500;
    }
}