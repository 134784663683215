.gap-footer {
    position: absolute;
    bottom: 0;
    width: 97%;
    border-top: 1px solid #d4d4d4;
    &__img {
        width: 60px;
        height: 34px;
        opacity: 0.7;
    }
    label {
        font-size: 12px;
        font-weight: 600;
        opacity: 0.7;
    }
}