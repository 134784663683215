// layout scaffolding styles
.gap-layout{
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    background: $app-bg-color;
    &__content{
        padding: 80px 3rem 0;
        min-height: 100vh;
        &--m-230{
            margin-left: 230px;
        }
        &--m-86{
            margin-left: 86px;
        }
    }
    &__left-sidebar{
        position: fixed;
        z-index: 999;
        overflow-y: auto;
        margin-top: 56px;
        max-height: calc(100vh - 56px);
    }

}

@media (max-width: 768px){
    .gap-layout{
        &__left-sidebar{
            left: -240px;
        }
        &__content{
            padding: 0 24px !important;
            &--m-230{
                margin-left: 0px !important;
            }
            &--m-86{
                margin-left: 0px !important;
            }
        }
        &--page-wrapper{
            margin: 80px 0px;
        }
    }

    .mini-sidebar .gap-layout__left-sidebar{
        position: absolute;
        top: 0px;
        z-index: 20;
        left: 0px;
    }
}

// Blank container
.gap-blank-container{
    padding: 0;
    overflow: hidden;
}

