.gap-sec-header{
    &__title{
        margin-bottom: 0;
    }
}

.gap-section-header{
    font-size: 0.8rem;
    opacity: 0.8;
    font-weight: 500;
}