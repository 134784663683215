.gap-info-box {
    padding: 0 12px;
    background: #f4f4f4;
    border-radius: 12px;
    border: 1px solid #d4d4d4;
    width: 99%;
    &__icon{
        width: 40px;
    }
    &__label {
        font-size: 12px;
        opacity: 0.7;
    }
    &__value {
        font-size: 14px;
        opacity: 0.88;
    }
}