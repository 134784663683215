// image preview object
//
// Use this class when you want a image preview
// Typically you'll want to use a `<img>`element.
//
// Markup:
// <img class="gap-image-preview" />
//
// Styleguide objects.image-preview

.gap-image-preview{
    width: 380px;
    height: 180px;
    border: 1px solid #d4d4d4;
    object-fit: contain;
    border-radius: 12px;
    &__abs{
        position: absolute;
    }
}